<template>
  <v-card
    v-if="serverData"
    elevation="0"
    hover
    class="d-block ma-0 pa-2 border-circular"
    :style="
      'background-image:linear-gradient(to right, ' +
      serverData.color[0] +
      ' , ' +
      serverData.color[1] +
      ' ) !important;'
    "
  >
    <!-- {{ item }} -->
    <div class="d-flex justify-center align-center">
      <div>
        <v-card-text
          :class="serverData.title == 'Orders' ? 'white--text' : 'white--text'"
        >
          <!-- <div class="h4 pl-1">{{ serverData.dateLabel }}</div> -->
          <div style="font-size: 26px" class="pl-1">
            {{ serverData.values }}
          </div>
        </v-card-text>
        <v-card-text
          class="h4"
          :class="serverData.title == 'Orders' ? 'white--text' : 'white--text'"
        >
          {{ serverData.title }}
          <!-- {{ serverData.icon }} -->
        </v-card-text>
      </div>
      <v-spacer></v-spacer>
      <div class="pr-4">
        <div class="rounded-circle">
          <!-- <inline-svg
            color="white"
            width="50px"
            height="50px"
            :src="serverData.icon"
          ></inline-svg> -->
          <img :src="serverData.icon" width="66px" height="66px" />
        </div>
      </div>
    </div>
  </v-card>
  <v-skeleton-loader v-else type="card"></v-skeleton-loader>
</template>

<script>
// import { getToken } from "@/core/services/jwt.service";
// import axios from "axios";
import ApiService from "@/core/services/api.service";

// import Swal from "sweetalert2";
export default {
  name: "FulfillmentOrdersCounterChart",
  props: { item: { required: true }, filterData: { required: true } },
  data: () => ({ serverData: null }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    loadDataFromServer() {
      let data = this.filterData;
      ApiService.post(`${this.item.url}`, data)
        .then((response) => {
          this.serverData = response.data.chart;
        })
        .catch(() => {});
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.serverData;
    },
  },
  watch: {
    filterData() {
      // console.log(val, oldVal);
      this.serverData = null;
      this.loadDataFromServer();
    },
  },
};
</script>

<style scoped>
.border-circular {
  border-radius: 12px;
  /* background-image: linear-gradient(to right, red , yellow); */
}
</style>
